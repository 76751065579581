var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Удаление проекта",
      "actions": _vm.actions
    }
  }, [_vm._v(" Вы действительно хотите удалить проект"), _c('br'), _vm._v(" \"" + _vm._s(_vm.project.name) + "\"? ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };