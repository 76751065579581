var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('data-table', _vm._b({
    ref: "table",
    attrs: {
      "table-id": "monitoring",
      "columns": _vm.columns,
      "loader": _vm.tableLoader,
      "loaderConfig": _vm.reportSettings,
      "item-id": "id",
      "selectable": false,
      "paginatable": _vm.paginatable,
      "configurable": _vm.configurable
    },
    scopedSlots: _vm._u([{
      key: "status",
      fn: function ({
        item
      }) {
        return [_c('div', {
          staticClass: "status-wrap"
        }, [item.initial_ready === 0 && item.status !== 0 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [item.status === 10 ? _c('span', {
          staticClass: "dot blue"
        }) : _vm._e(), item.status === 20 ? _c('span', {
          staticClass: "dot green"
        }) : _vm._e(), _c('spinner', {
          attrs: {
            "dark": true
          }
        }), _c('span', {
          staticClass: "status-wrap__item__text"
        }, [_vm._v(" Первичный сбор данных ")])], 1) : item.status === 0 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "dot red"
        }), _c('span', {
          staticClass: "status-wrap__item__text"
        }, [_vm._v("Ошибка")])]) : item.status === 10 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "dot blue"
        }), _c('span', {
          staticClass: "status-wrap__item__text"
        }, [_vm._v("Черновик")])]) : item.status === 20 ? _c('div', {
          staticClass: "status-wrap__item"
        }, [_c('span', {
          staticClass: "dot green"
        }), _c('span', {
          staticClass: "status-wrap__item__text"
        }, [_vm._v("Работает")])]) : _vm._e()])];
      }
    }, _vm.date1 && _vm.date2 ? {
      key: "dynamic",
      fn: function ({
        item
      }) {
        return [_c('keywords-positions-chart', {
          attrs: {
            "date": _vm.date1,
            "date2": _vm.date2,
            "item": item
          }
        })];
      }
    } : null], null, true)
  }, 'data-table', _vm.$attrs, false));
};
var staticRenderFns = [];
export { render, staticRenderFns };