var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('SeoWrapper', [_c('div', {
    staticClass: "lists-page"
  }, [_c('div', {
    staticClass: "lists-page__content"
  }, [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center"
          }
        }, [_c('span', {
          staticClass: "lines",
          class: {
            red: _vm.availableQueries <= 0
          }
        }, [_vm._v(" Доступно запросов: " + _vm._s(_vm.availableQueries > 0 ? _vm.availableQueries : 0) + " из " + _vm._s(_vm.$store.state.seo.queriesLimit) + " "), _vm.availableQueries <= 0 ? _c('span', [_vm._v(" Исчерпан лимит запросов на вашем тарифе ")]) : _vm._e()]), _c('button', {
          staticClass: "btn-outline btn-width-auto",
          on: {
            "click": _vm.onCreateProject
          }
        }, [_c('img', {
          attrs: {
            "src": require(`@/assets/images/icons/plus.svg`)
          }
        }), _vm._v(" Новый проект ")])])];
      },
      proxy: true
    }])
  }, [_vm._v(" Мои проекты для мониторинга ")]), _c('div', {
    staticClass: "main-search mt32"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    ref: "input",
    staticClass: "main-search-input",
    attrs: {
      "type": "text",
      "placeholder": 'Поиск по проектам'
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }), [_c('svg', {
    attrs: {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M7.27001 14.5392C8.94756 14.5392 10.4897 13.9622 11.7202 13.0053L15.715 17L17 15.7151L13.0051 11.7204C13.963 10.4891 14.54 8.94708 14.54 7.26962C14.54 3.26133 11.2785 0 7.27001 0C3.26151 0 0 3.26133 0 7.26962C0 11.2779 3.26151 14.5392 7.27001 14.5392ZM7.27001 1.8174C10.2771 1.8174 12.7225 4.26272 12.7225 7.26962C12.7225 10.2765 10.2771 12.7218 7.27001 12.7218C4.26295 12.7218 1.8175 10.2765 1.8175 7.26962C1.8175 4.26272 4.26295 1.8174 7.27001 1.8174Z",
      "fill": "black",
      "fill-opacity": "0.9"
    }
  })])]], 2), _c('monitoring-table', {
    key: _vm.tableKey,
    staticClass: "mt32",
    attrs: {
      "hasItems": _vm.hasProjects,
      "report-settings": _vm.reportSettings
    },
    on: {
      "edit": _vm.onEdit,
      "copy": _vm.onCopy,
      "remove": _vm.onRemove
    }
  })], 1), _c('modal-add-project', {
    ref: "modalAddProject",
    on: {
      "complete": _vm.onAdded
    }
  }), _c('modal-edit-project', {
    ref: "ModalEditProject"
  }), _c('modal-remove-project', {
    ref: "modalRemoveProject"
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };