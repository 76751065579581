import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import DataTable from "@/components/DataTable/DataTable.vue";
import KeywordsPositionsChart from "@/components/Product/KeywordsPositionsChart";
import Spinner from "@/components/Loading/Spinner";
import {
// dateRangeBoundaries,
// dateRangeFromDate,
// today,
dateRangeDaysArray, dateRangeFrom } from "@/utils/dates";
export default {
  components: {
    DataTable,
    Spinner,
    KeywordsPositionsChart
  },
  props: {
    reportSettings: {
      type: Object,
      required: true
    },
    configurable: {
      type: Boolean,
      default: true
    },
    paginatable: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const dateRange = dateRangeFrom(1, 6);
    const dateRangeArray = dateRangeDaysArray(dateRange[0], dateRange[1]);
    return {
      dateRangeArray,
      prev: null,
      refreshInterval: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    async tableLoader({
      query
    }) {
      // prevent sorting by dynamic field
      if (query.sort == "dynamic") {
        delete query["sort"];
        delete query["sort_dir"];
      }
      let result = await this.$store.dispatch("seo/getProjects", {
        ...query,
        ...this.reportSettings
      });

      // Mapping for chart
      const data = result.data.map(item => {
        let positionDateObject = {};
        item.stats.forEach(stat => {
          const date = stat["snapshotDate"];
          const position = stat["avg_position"];
          positionDateObject = {
            ...positionDateObject,
            [date]: position
          };
        });
        let dateRangeObject = {};
        this.dateRangeArray.forEach(date => {
          dateRangeObject = {
            ...dateRangeObject,
            [date]: -1
          };
        });
        let finalObject = {};
        Object.keys(dateRangeObject).forEach(date => {
          if (positionDateObject[date] >= 0) {
            finalObject[date] = positionDateObject[date];
          } else {
            finalObject[date] = -1;
          }
        });
        return {
          ...item,
          ...finalObject
        };
      });
      result = {
        items: [...data],
        total: result.total,
        success: result.success,
        message: result.message
      };
      return result;
    }
  },
  mounted() {
    this.refreshInterval = setInterval(async () => {
      this.refresh();
    }, 10000);
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },
  computed: {
    date1() {
      return this.dateRangeArray[0];
    },
    date2() {
      return this.dateRangeArray[this.dateRangeArray.length - 1];
    },
    columns() {
      let columns = [{
        title: "Название",
        show: true,
        name: "name",
        width: 200,
        type: "link",
        noOverflow: true,
        getLink: item => {
          return {
            route: {
              name: "ProjectInfo",
              params: {
                project_id: item.id
              }
            },
            text: item.name,
            target: "_blank"
          };
        }
      }, {
        title: "Маркетплейс",
        show: true,
        name: "datasource",
        width: 130
      }, {
        title: "Запросов",
        show: true,
        name: "total_queries",
        type: "number",
        width: 120
      }, {
        title: "Товаров",
        show: true,
        name: "total_products",
        type: "number",
        width: 120
      }, {
        title: "Конкурентов",
        show: true,
        name: "total_competitors",
        type: "number",
        width: 140
      }, {
        title: "Запросов с позициями",
        show: true,
        name: "top1000",
        width: 200
      }, {
        title: "Средняя позиция",
        show: true,
        name: "avg_position",
        width: 100
      }, {
        title: "Средняя позиция за 7 дней",
        show: true,
        name: "dynamic",
        type: "slot",
        width: 160,
        position: 6,
        sortable: false
      }, {
        title: "ТОП 4",
        show: true,
        name: "top4",
        width: 100
      }, {
        title: "ТОП 12",
        show: true,
        name: "top12",
        width: 100
      }, {
        title: "ТОП 100",
        show: true,
        name: "top100",
        width: 100
      }, {
        title: "Статус",
        show: true,
        name: "status",
        type: "slot",
        width: 300
      }, {
        title: "Дата создания",
        show: true,
        type: "date",
        name: "created_at",
        width: 140
      }, {
        title: "",
        show: true,
        name: "_actions",
        type: "actions",
        actions: [{
          title: "Редактировать",
          action: (item, {
            refresh
          }) => {
            this.$emit("edit", {
              item,
              context: {
                refresh
              }
            });
          }
        },
        // {
        //     title: "Копировать",
        //     action: (item, { refresh }) => {
        //         this.$emit("copy", {
        //             item,
        //             context: { refresh },
        //         });
        //     },
        // },
        {
          title: "Удалить",
          action: (item, {
            refresh
          }) => {
            this.$emit("remove", {
              item,
              context: {
                refresh
              }
            });
          }
        }],
        width: 40
      }];
      if (this.small) {
        columns = columns.filter(column => {
          return column.name === "name" || column.name === "datasource" || column.name === "total_queries" || column.name === "avg_position" || column.name === "dynamic";
        });
      }
      return columns;
    }
  }
};